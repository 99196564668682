import ProjectConfigCode from './blank_slate/ProjectConfigCode'

export default class BlankSlate extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      jsPackageManager: 'npm'
    }

    @selectNpm  = @selectNpm.bind(this)
    @selectYarn = @selectYarn.bind(this)

  selectNpm: ->
    @setState({ jsPackageManager: 'npm' })

  selectYarn: ->
    @setState({ jsPackageManager: 'yarn' })

  # NEW_STACK - Add new render for new stack
  render: ->
    if @props.project.stack == 'laravel'
      @renderLaravel()
    else if @props.project.stack == 'lingui'
      @renderLingui()
    else if @props.project.stack == 'angular'
      @renderAngular()
    else
      @renderRails()

  renderRails: ->
    languageCodes       = [@props.project.sourceLanguageCode].concat(@props.project.targetLanguageCodes)
    joinedLanguageCodes = _.map(languageCodes, (code) ->
      if _.includes(code, '-') then ":\"#{code}\"" else ":#{code}"
    ).join(', ')

    <div className="row instructions">
      <div className="col-md-12">
        <h1>Setup</h1>

        { @renderGithubLink('translation/rails') }

        <p>1. Add <code>gem 'translation'</code> to your Gemfile.</p>
        <p>2. Create <code>config/initializers/translation.rb</code> with the following content:</p>

        <ProjectConfigCode project={@props.project} />

        <p>
          3. Add this line to your <code>config/application.rb</code>:
        </p>

        <pre>
          config.i18n.available_locales <span className="orange">=</span> [<span className="green">{joinedLanguageCodes}</span>]
        </pre>

        <p style={{ color: '#999999' }}>
          3b. (optional) If you previously used FastGettext, read this <a href="/blog/coming-from-fast-gettext" target="_blank">blog post</a>.
        </p>

        <p style={{ color: '#999999' }}>
          3c. (optional) Add <a href="/blog/set-current-locale-in-your-rails-app" target="_blank">our generic before filter</a> to your <code>ApplicationController</code>:
        </p>

        <pre>
          <span className="blue">before_action</span> <span className="green">:set_locale</span>
        </pre>

        <p>4. Execute <code>rake translation:init</code> in your project's directory.</p>
        <p>5. This page should refresh automatically when the initialization is complete.</p>

        { @renderGuidesLink() }
        { @renderNeedHelp() }
      </div>
    </div>

  renderLaravel: ->
    <div className="row instructions">
      <div className="col-md-12">
        <h1>Setup</h1>

        { @renderGithubLink('translation/laravel') }

        <p>1. Execute <code>composer require tio/laravel</code> in your application directory.</p>
        <p>2. Create <code>config/translation.php</code> with the following content:</p>

        <ProjectConfigCode project={@props.project} />

        <p style={{ color: '#999999' }}>
          2b. (optional) Replace the API key in the config file with <code>env('TRANSLATIONIO_KEY')</code> and add it in your <code>.env</code> file.
        </p>

        <p style={{ color: '#999999' }}>
          2c. (optional) Add <a href="https://github.com/translation/laravel#globally" target="_blank">our generic 'set.locale' Middleware</a> to your <code>routes/web.php</code> file.
        </p>

        <p>3. Execute <code>php artisan translation:init</code> in your application directory.</p>
        <p>4. This page should refresh automatically when the initialization is complete.</p>

        { @renderGuidesLink() }
        { @renderNeedHelp() }
      </div>
    </div>

  renderLingui: ->
    <div className="row instructions">
      <div className="col-md-12">
        <h1>Setup</h1>

        { @renderGithubLink('translation/lingui') }

        <p>
          1. Check that your <a href="https://lingui.dev/" target="_blank">Lingui</a> project is properly configured,
             or install the required dependencies:
        </p>

        { @renderNpmYarnSelector() }
        <pre className="with-selector">
          { @renderLinguiInstallLines() }
        </pre>

        <p>
          2. Add these lines to your <code>package.json</code> to make your life easier.
        </p>

        <pre>
          {'{'}<br/>
          &nbsp;&nbsp;<span className="blue">"scripts"</span>: {'{'}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="blue">"sync"</span>: <span className="blue">"lingui extract --overwrite && lingui compile"</span>,<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="blue">"sync_and_purge"</span>: <span className="blue">"lingui extract --overwrite --clean && lingui compile"</span><br/>
          &nbsp;&nbsp;{'}'}<br/>
          {'}'}
        </pre>

        <p>3. Create or update <code>.linguirc</code> with the following content at the root of your application:</p>

        <ProjectConfigCode project={@props.project} />

        <p>
          4. Setup your <a href="https://lingui.dev/tutorials/react" target="_blank">React</a>, <a href="https://lingui.dev/tutorials/react-native" target="_blank">React Native</a> or <a href="https://lingui.dev/tutorials/javascript" target="_blank">JavaScript</a> application.
        </p>

        <p>
          5. Localize your application using the <code>
            <a href="https://github.com/translation/lingui#react-jsx-syntax" target="_blank">&lt;Trans&gt;React syntax&lt;/Trans&gt;</a>
          </code> or the <code>
            <a href="https://github.com/translation/lingui#javascript-syntax" target="_blank">t`JavaScript syntax`</a>
          </code>.
        </p>

        <p>6. Execute this line in your application directory:</p>

        { @renderNpmYarnSelector() }
        <pre className="with-selector">
          { @renderLinguiExecutionLines() }
        </pre>

        <p>7. This page should refresh automatically when the initialization is complete.</p>

        { @renderGuidesLink() }
        { @renderNeedHelp() }
      </div>
    </div>

  renderAngular: ->
    <div className="row instructions">
      <div className="col-md-12">
        <h1>Setup</h1>

        { @renderGithubLink('translation/angular') }

        <p>
          1. Install our <a href="https://github.com/translation/angular" target="_blank">package</a> to your project:
        </p>

        { @renderNpmYarnSelector() }
        <pre className="with-selector">
          { @renderAngularInstallLines() }
        </pre>

        <p>
          2. Create <code>tio.config.json</code> with the following content at the root of your application:
        </p>

        <ProjectConfigCode project={@props.project} />

        <p>
          3. Add these lines to your <code>package.json</code>:
        </p>

        <pre>
          {'{'}<br/>
          &nbsp;&nbsp;<span className="blue">"scripts"</span>: {'{'}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="blue">"extract"</span>: <span className="blue">"ng extract-i18n --output-path=src/locale"</span>,<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="blue">"translation:init"</span>: <span className="blue">"npm run extract && tio init"</span>,<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="blue">"translation:sync"</span>: <span className="blue">"npm run extract && tio sync"</span>,<br/>
          &nbsp;&nbsp;{'}'}<br/>
          {'}'}
        </pre>

        <p style={{ color: '#999999' }}>
          Note: if you are using Angular v10 or lower, replace <strong>extract-i18n</strong> by <strong>xi18n</strong> in the "extract" command.
        </p>

        <p>
          4. Setup the <a href="https://angular.io/guide/i18n-common-add-package" target="_blank"><code>@angular/localize</code></a> package and add the <a href="https://angular.io/guide/i18n-common-merge#define-locales-in-the-build-configuration" target="_blank">i18n options</a> in your <code>angular.json</code> file.
        </p>

        <p>
          5. Localize your application using <code>
            <a href="https://github.com/translation/angular#i18n-attribute-in-templates" target="_blank">
              &lt;p i18n&gt;source text&lt;/p&gt;
            </a>
          </code> or the <code>
            <a href="https://github.com/translation/angular#localize-for-literal-strings-in-code" target="_blank">
              $localize `source text`
            </a>
          </code> syntax.
        </p>

        <p>6. Execute this line in your application directory:</p>

        { @renderNpmYarnSelector() }
        <pre className="with-selector">
          { @renderAngularExecutionLines() }
        </pre>

        <p>6. This page should refresh automatically when the initialization is complete.</p>

        { @renderGuidesLink() }
        { @renderNeedHelp() }
      </div>
    </div>

  renderLinguiInstallLines: ->
    if @state.jsPackageManager == 'yarn'
      <span className="blue">
        yarn add --dev @lingui/cli<br/>
        yarn add --dev @lingui/babel-plugin-lingui-macro<br/>
        yarn add @lingui/react
      </span>
    else
      <span className="blue">
        npm install --save-dev @lingui/cli<br/>
        npm install --save-dev @lingui/babel-plugin-lingui-macro<br/>
        npm install @lingui/react
      </span>

  renderLinguiExecutionLines: ->
    if @state.jsPackageManager == 'yarn'
      <span className="blue">
        yarn sync
      </span>
    else
      <span className="blue">
        npm run sync
      </span>

  renderAngularInstallLines: ->
    if @state.jsPackageManager == 'yarn'
      <span className="blue">
        yarn add @translation/angular
      </span>
    else
      <span className="blue">
        npm install @translation/angular
      </span>

  renderAngularExecutionLines: ->
    if @state.jsPackageManager == 'yarn'
      <span className="blue">
        yarn translation:init
      </span>
    else
      <span className="blue">
        npm run translation:init
      </span>

  renderNpmYarnSelector: ->
    npmClasses  = "option"
    yarnClasses = 'option'
    npmClasses  += " selected" if @state.jsPackageManager == 'npm'
    yarnClasses += " selected" if @state.jsPackageManager == 'yarn'

    <div className="code-selector">
      <div className="options" data-type="package">
        <div className={npmClasses} data-value="npm" onClick={@selectNpm}>NPM</div>
        <div className={yarnClasses} data-value="yarn" onClick={@selectYarn}>YARN</div>
      </div>
    </div>

  renderGithubLink: (repository) ->
    url = "https://github.com/#{repository}"

    <div className="github-link float-end">
      Code and documentation on
      <a href={url} className="badge" target="_blank">
        <i className="fab fa-github"></i> {repository}
      </a>
    </div>

  renderGuidesLink: ->
    <div className="guides-link-section">
      <hr />
      <p>
        <i className="far fa-lightbulb"></i>
        &nbsp;
        Check our <a href="/docs#guides" target="_blank">step-by-step guides</a> to make the best of your localization.
      </p>
    </div>

  renderNeedHelp: ->
    <div className="float-end contact">
      Need help? Contact us on <a href="mailto:contact@translation.io" target="_blank">contact@translation.io</a>
    </div>

